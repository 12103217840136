import {useFooter} from "@views/shared/Footer/useFooter";
import styled from "styled-components";
import TermServicePopup from "@components/TermServicePopup";
import Loading from "@components/Loading";
import {formatDate} from "@utils/format";


function LoginFooter() {
  const {
    isModalVisible,
    setIsModalVisible,
    modalTitle,
    modalContent,
    handleCancel,
    handleSelectTermsVersion,
    currentTermVersion,
    pageType,
    showModal,
    termsByVersion,
    termsVersionList,
    footerRender,
  } = useFooter();

  return (
    <Container>
      <FooterWrap>
        <PolicyWrap>
          <span
            onClick={() => {
              showModal('001');
            }}
            className="policy-button"
          >
            개인정보처리방침
          </span>
          <span>|</span>
          <span
            className="policy-button"
            onClick={() => {
              showModal('002');
            }}
          >
            이용약관
          </span>
          <span>|</span>
          <CopyRightWrap>
            Copyright © GSretail Co. Ltd., ALL Right Reserved.
          </CopyRightWrap>
        </PolicyWrap>
        <DetailInfoWrap>
          ㈜GS리테일 대표이사 : 허서홍 | 주소 : 서울특별시 강남구 논현로 508 GS강남타워 (역삼679번지 GS타워) | 사업자등록번호 : 116-81-18745 | 개인정보보호책임자 : 이종혁
        </DetailInfoWrap>
      </FooterWrap>
      {isModalVisible && (
        <TermServicePopup
          title={modalTitle()}
          visible={isModalVisible}
          footer={footerRender()}
          content={termsByVersion?.status === 'pending' ? (
            <div style={{position: 'relative', height: '540px'}}>
              <Loading isLoading />
            </div>
          ) : modalContent}
          onCancel={handleCancel}
          onSelectVersion={handleSelectTermsVersion}
          currentVersion={currentTermVersion}
          versionList={termsVersionList?.data?.length && termsVersionList?.data?.map((version) => ({
            label: `v${version?.trmsVerCns} (변경일자: ${formatDate(version?.actDt)})`,
            value: version?.trmsVerCns,
          }))}
          isShowVersionSelect
        />
      )}
    </Container>
  );
}

export default LoginFooter;

const Container = styled.div`
    white-space: nowrap;
    width: 100%;
    min-width: var(--footer-minWidth);
    background-color: transparent;
    margin-top: 36px;
`;

const DetailInfoWrap = styled.div`
    display: flex;
    align-items: center;
    //width: 100%;
    height: 18px;
    color: var(--color-steelGray-300);
    margin: 8px 0 24px;
    font-size: 12px;
    line-height: 150%;
`;

const CopyRightWrap = styled.div`
    height: 18px;
    color: var(--color-steelGray-300);
    font-size: 12px;
    line-height: 150%;
`;

const PolicyWrap = styled.div`
    height: 38px;
    padding: 10px 0;
    display: flex;
    gap: 19.5px;

    > span:first-child {
        width: 83px;
        line-height: 150%;
        font-size: 12px;
        font-weight: 700;
        color: var(--main-blue-blue-800, #04459C);
        display: inline-block;
    }

    > span:nth-child(2), > span:nth-child(4) {
        width: 1px;
        height: 18px;
        color: var(--color-steelGray-300);
        font-size: 12px;
        line-height: 150%;
    }

    > span:nth-child(3) {
        width: 42px;
        font-size: 12px;
        line-height: 150%;
        color: var(--main-steel-gray-steel-gray-700, #555F75);
        display: inline-block;
    }

    > span.policy-button {
        cursor: pointer;
    }
`;

const FooterWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    font-size: 12px;
    word-break: keep-all;
`;



