export const MENU_CODE = 'SUBSCRIBE';

export const MENU_MESSAGES = {
  SS_T_001: '검색어는 최소 2자 이상 입력해주세요.',
  SS_T_002: '검색할 항목을 선택해주세요.',
  SS_T_003: '검색어는 최소 2자 이상 입력해주세요.',
  SS_A_200: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  SS_A_201: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  SS_A_001: '이미 추가된 중분류입니다.',
  SS_A_002: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  SS_A_003: '요금제 금액과 정산 금액이 일치하지 않습니다. 다시 확인해주세요.',
  SS_A_004: '중분류별 정산 금액은 최소 10,000원 이상 설정 가능합니다.',
  SS_A_005: '중분류코드별 정산 금액을 등록하시겠어요?',
  SS_A_026: '구독 상태를 다시 확인해주세요.',
  SS_T_006: '요금제를 선택해주세요.',
  SS_T_007: '정산코드를 선택해주세요.',
  SS_T_008: '중분류코드를 선택해주세요.',
  SS_A_006: '요금제 금액과 정산 금액이 일치하지 않습니다. 다시 확인해주세요.',
  SS_A_007: '구독 상태를 다시 확인해주세요.',
  SS_A_072: '탈퇴한 판매채널은 구독 신청을 할 수 없습니다. 판매채널 상태를 확인해주세요.',
  SS_A_066: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_T_024: '요금제를 선택해주세요.',
  SS_T_025: '변경 정산코드를 선택해주세요.',
  SS_A_008: '요금제 금액과 정산 금액이 일치하지 않습니다. 다시 확인해주세요.',
  SS_A_060: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_T_029: '변경 정산코드를 선택해주세요.',
  SS_T_030: '변경 중분류코드를 선택해주세요.',
  SS_A_061: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_062: '요금제 금액과 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_009: '구독 기간을 1년 연장 하시겠어요?\n연장 후 구독 기간은 {일시} ~ {일시} 입니다.',
  SS_A_010: '기존에 사용했던 요금제는 판매가 중지되었습니다.\n새로운 요금제로 구독을 연장하시겠어요?',
  SS_A_011: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_068: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_067: '요금제 변경 신청 승인이 불가능합니다.\n구독 상태를 다시 확인해주세요.',
  SS_A_012: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_T_009: '정산코드를 선택해주세요.',
  SS_T_010: '중분류코드를 선택해주세요.',
  SS_A_069: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_104: '해지 신청 승인이 불가능합니다. 구독 상태를 다시 확인해주세요.',
  SS_A_013: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_065: '해지 철회가 불가능한 구독건입니다.\n마스터 회원의 상태를 확인해주세요.',
  SS_A_105: '해지 철회가 불가능한 구독건입니다.\n구독 상태를 다시 확인해주세요.',
  SS_T_011: '검색어는 최소 2자 이상 입력해주세요.',
  SS_A_014: '이미 추가된 정산코드입니다.',
  SS_A_015: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  SS_A_016: '정산코드별 정산 금액은 최소 1만원 이상 설정 가능합니다.',
  SS_A_017: '합계와 월 정산대상금액(VAT 별도)이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_018: '정산코드별 정산 금액을 등록하시겠어요?',
  SS_T_012: '검색어는 최소 2자 이상 입력해주세요.',
  SS_A_019: '이미 추가된 중분류코드입니다.',
  SS_A_020: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  SS_A_021: '중분류코드가 등록되지 않은 정산코드가 존재합니다.',
  SS_A_022: '중분류코드별 정산 금액은 최소 1만원 이상 설정 가능합니다.',
  SS_A_023: '%PV2409001%의 정산금액과 중분류 합계가 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_024: '중분류 합계와 월 정산대상금액(VAT 별도)이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_025: '중분류코드별 정산 금액을 등록하시겠어요?',
  SS_A_118: '구독 상태를 다시 확인해주세요.',
  SS_A_119: '요금제 변경 철회가 완료되었습니다.\n기존 요금제 및 정산 정보로 GIP 서비스 이용이 가능합니다.',
  SS_A_122: '구독해지 신청이 불가능한 구독건입니다.\n구독 상태를 확인해주세요.',
  SS_A_123: '구독 해지 신청이 완료되었습니다.\n당월까지 서비스 이용이 가능하며, 익월 1일 00시 구독 서비스가 종료됩니다.',
  SS_A_033: '선택한 요금제 변경 신청을 철회 하시겠어요?',
  SS_A_034: '요금제 변경 철회가 완료되었습니다.\n성공: {N}건\n실패: {N}건',
  SS_T_016: '검색어는 최소 2자 이상 입력해주세요.',
  SS_A_038: '해지완료 처리가 완료되었습니다.\n성공: {N}건\n실패: {N}건',
  SS_A_041: '선택한 구독 해지를 철회 하시겠어요?\n구독 상태를 다시 확인해주세요.',
  SS_A_043: '이미 추가된 중분류입니다.',
  SS_A_045: '요금제 금액과 정산 금액이 일치하지 않습니다. 다시 확인해주세요.',
  SS_A_047: '중분류코드별 정산 금액을 등록하시겠어요?',
  SS_A_048: '구독 상태를 다시 확인해주세요.',
  SS_T_021: '요금제를 선택해주세요.',
  SS_A_050: '구독 상태를 다시 확인해주세요.',
  SS_A_051: '요금제 금액과 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_052: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_T_026: '변경 중분류코드를 선택해주세요.',
  SS_A_053: '이미 구독중인 요금제로는 변경 할 수 없습니다. 다른 요금제를 선택하여 \'요금제 변경 신청\' 해주세요.',
  SS_A_054: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_063: '요금제 금액과 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_055: '구독 기간을 1년 연장 하시겠어요?\n연장 후 구독 기간은 2023.6.1 00:00 ~ 2024.04:30 23:59 입니다.',
  SS_A_056: '기존에 사용했던 요금제는 판매가 중지되었습니다.\n새로운 요금제로 구독을 연장하시겠어요?',
  SS_A_057: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_058: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_T_027: '정산코드를 선택해주세요.',
  SS_T_028: '중분류코드를 선택해주세요.',
  SS_A_059: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_A_070: '정산코드, 중분류코드 변경 후 \'저장\' 버튼을 클릭해야 변경 내용이 저장됩니다.\n정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?',
  SS_T_080: '검색어는 최소 2자 이상 입력해주세요.',
  SS_T_081: '검색할 항목을 선택해주세요.',
  SS_A_090: '요청사항이 정상적으로 처리되지 않았습니다.\n잠시 후 다시 시도해주세요.',
  SS_A_202: '정산코드를 먼저 설정 후 중분류코드 선택이 가능합니다.\n정산코드를 설정하시겠어요?',
  SS_A_107: '현재 구독중인 요금제입니다.\n 동일한 요금제로는 변경 신청을 할 수 없습니다.',
  SS_T_100: '요금제를 선택해주세요.',
  SS_T_101: '정산 코드를 선택해주세요.',
  SS_T_102: '중분류 코드를 선택해주세요.',
  SS_T_105: '부가 요금제를 선택해주세요.',
  SS_T_106: "법인번호는 최대 100개까지 조회할 수 있습니다.",
  SS_A_074: '정산 정보(정산 코드, 중분류 코드)를 다시 한 번 확인해주세요. 정산 정보 미입력 또는 금액이 일치하지 않을 경우 구독 정보 저장이 불가능합니다.',
  SS_A_073: '저장하시겠어요?',
  SS_A_124: '요금제 금액과 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_131: '요금제 금액과 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_132: '요금제 변경 신청을 승인하시겠어요?',
  SS_A_135: '요금제 금액과 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_143: '부가 요금제 금액과 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  SS_A_071: '취소하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  SS_T_112: '검색어는 최소 2자 이상 입력해주세요.',
  SS_T_113: '검색할 항목을 선택해주세요.',
  SS_T_114: '검색어는 최소 2자 이상 입력해주세요.',
  SS_T_115: '검색할 항목을 선택해주세요.',
  SS_A_102: '해지 신청을 승인하시겠어요?',
  SS_A_995: '정산 정보가 변경되었습니다.\n변경된 정산 정보를 저장하고 구독 해지를 진행하시겠어요?',
  SS_A_991: '정산 정보가 변경되었습니다.\n변경된 정산 정보를 저장하고 해지 철회를 진행하시겠어요?',
  SS_A_990: '정산 정보가 변경되었습니다.\n변경된 정산 정보를 저장하고 해지 승인을 진행하시겠어요?',
  SS_A_992: '정산 정보가 변경되었습니다.\n변경된 정산 정보를 저장하고 요금제 변경 철회를 진행하시겠어요?',
  SS_A_993: '정산 정보가 변경되었습니다.\n변경된 정산 정보를 저장하고 요금제 변경 승인을 진행하시겠어요?',
  SS_A_994: '정산 정보가 변경되었습니다.\n변경된 정산 정보를 저장하고 구독 기간 연장을 진행하시겠어요?',
  SS_A_108: '취소하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  SS_A_134: '요금제 변경 신청 승인이 완료되었습니다.',
  SS_A_112: '요금제 변경 신청이 완료되었습니다.\n익월 1일 00시부터 변경된 요금제가 적용됩니다.',
  SS_A_103: '해지 신청 승인이 완료되었습니다.',
  SS_A_129: '구독 해지 철회가 완료되었습니다.\n기존과 동일한 요금제와 기간으로 GIP 서비스 이용이 가능합니다.',
  SS_A_121: '구독 신청이 취소되었습니다.',
  SS_A_150: '저장하시겠어요?',
  SS_A_162: '해지 신청이 완료되었습니다.\n당월까지 서비스 이용이 가능하며, 익월 1일 00시 부가 서비스가 종료됩니다.',
  SS_A_165: '해지 철회가 완료되었습니다.\n기존과 동일하게 부가 서비스 이용이 가능합니다.\n단, 기본 구독 서비스가 종료될 경우 부가서비스는 종료됩니다.',

};
