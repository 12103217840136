import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Images from '@Images';
import AdminPageLayout from '@components/AdminPageLayout';
import { CustomTableTop, StyledTitle } from '@styles/Table';

import { useCommonCode } from '@hooks/useCommonCode';
import { useUserRole } from '@hooks/useUserRole';

import { MENU_MESSAGES } from '@constants/messages/subscribe';
import Button from '@components/ButtonNew';

import Space from '@components/Space';

import RGDTableSettlementManager from '@components/ReactdatagridSettlementManager';

import SettlementManagerModal from '@views/shared/Modal/SettlementManagerModal';

import { useLocation } from 'react-router-dom';

import {
  getOptionSearchKeywords,
  initialState,
  optionAll,
  searchBySalesChannel,
} from './constant';
import { getSettlementManagementList, postSettlementStatus, resetSettlementManagementList,
  resetPostSettlementStatusState} from '../../redux/slice';

import { RDGColumnOptionsV2SummaryPage } from '../../constants';
import {
  FooterWrap,
  SearchPaper,
  StyledForm,
  TableWrap,
  StyledMultipleRow,
  StyledSubscriptionStatus,
  StyledSingleFormItem,
  StyledCheckBoxEContract,
  StyledSelectItem,
  StyledCheckGroupItem,
  StyledTypeTextOrTextArea,
} from './styled';
import SelectedCompanyListModal from '../Modal/SelectedCompanyListModal';

function SettlementCompanyList() {
  const formRef = useRef();
  const tableRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const { settlementManagementList, postSettlementStatusState } = useSelector((store) => store.subscribeManagement.settlementManger);

  const { getSelectOptionsByCode } = useCommonCode();

  const [state, setState] = useState(initialState);
  const [searchParams, setSearchParams] = useState({});
  const [listReload, setListReload] = useState(false);

  const [currentSelected, setCurrentSelected] = useState([]);
  const [currentSelectedOption, setCurrentSelectedOption] = useState("003");

  const [modalContents, setModalContents] = useState({type:null, status:null, count:0});
  const salesChnlCdRadioOptions = getSelectOptionsByCode(searchBySalesChannel, optionAll);
  const [compListBaseData, setcompListBaseData] = useState(null);
  const [showCompPopup, setShowCompPopup] = useState(false);

  const { isGSAdmin } = useUserRole();

  const subtitles = isGSAdmin ? [
    "정산제외대상 여부만 관리 가능합니다. 상세 조회는 ‘기본 구독 관리' 메뉴를 이용해 주세요.",
    '(',
  ] : [];

  const handleGetSbscList = (params) => {
    if (isGSAdmin) {
      dispatch(getSettlementManagementList({ params }));
    }
  };


  const handleCloseModal = (isDone) => {
    setModalContents({type:null, status:"READY", count:0})
    setShowModal(false);


    if (isDone) {
      const params = {
        ...searchParams,
      };
      handleGetSbscList(params);
      handleClickSelectNone();
    }
  }


  useEffect(() => {
    const sbscData = settlementManagementList;
    setState({
      ...state,
      data: (Array.isArray(sbscData?.data)) ? sbscData?.data : [],
      loading: sbscData.status === 'pending',
    });
  }, [settlementManagementList]);




  useEffect(() => {
    if (listReload) {
      formRef.current.submit();
    }

    setListReload(false);
  }, [listReload]);

  useEffect(() => {
    // Reset input if user selects corperation number
    if (currentSelectedOption === '003') {
      formRef.current?.setValue({
        srchKwrd: null,
      });
    }
  }, [currentSelectedOption]);

  const onSubmit = (data) => {
    const params = {
      isSbscSetlExclusion: data.isSbscSetlExclusion || "",
      mdCtrtStCd: data.mdCtrtStCd || '', // E-contract status code
      rangeTypCd: data.rangeTypNm || '', // 기간유형코드
      regEndDtm: data.regEndDtm ? `${data.regEndDtm}` : '', // 종료일
      regStDtm: data.regStDtm ? `${data.regStDtm}` : '', // 시작일
      salesChnlCd: data.salesChnlCd ? data.salesChnlCd : '', // 판매채널코드
      sbscStCd: data.sbscStCd ? data.sbscStCd : '', // 구독상태코드
      sbscStDvCd: data.sbscStDvCd ? data.sbscStDvCd : '', // 구독상태구분코드
      srchKwrd: data.srchKwrd ? data.srchKwrd : '', // 검색키워드
      srchKwrdTypNm: data.srchKwrdTypNm ? data.srchKwrdTypNm : '', // 검색어유형코드
    }

    handleGetSbscList(params);
    setSearchParams(params);
    updateState({ ...state });
  };




  const onFormItemChange = () => {
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };


  // 정산제외대상 여부 select 변경
  const settlementExcludeStateChange = useCallback((v) => {
    formRef.current.setValue({
      isSbscSetlExclusion: v,
    });
  }, []);


  useEffect(() => {
    if (postSettlementStatusState.status === 'success') {
      setModalContents((prev) => ({...prev, status:"DONE"}));
    }
  }, [postSettlementStatusState])




  const updateState = (value) => {
    setState({ ...state, ...value });
  };



  const changeSelectOption = (type) => {
    setCurrentSelectedOption(type);
  };

  const onChange = (data) => {
    if (currentSelectedOption === '003') {
      const filteredWord = data.replace(/[^0-9,]/g, '');
      formRef.current.setValue({ srchKwrd: filteredWord });
    }
  };




  const handleClickExcludeBtn = () => {
    setModalContents({
      type:"EXCLUDE",
      status: 'READY',
      count: calculateComp(true).length,
    })
    setShowModal(true);
  };


  const handleClickIncludeBtn = () => {
    setModalContents({
      type:"INCLUDE",
      status: 'READY',
      count: calculateComp(false).length,
    })
    setShowModal(true);
  };


  const handleClickExcludeProcessInModal = () => {
    if (modalContents?.type === "EXCLUDE") {
      const params = {
        corpSalesChnlList:calculateComp(true),
        useYn:"Y",
      }


      dispatch(postSettlementStatus({ params }));
    }
    if (modalContents?.type === "INCLUDE") {
      const params = {
        corpSalesChnlList:calculateComp(false),
        useYn:"N",
      }
      dispatch(postSettlementStatus({ params }));
    }
  }




  const handleRowSelectClick = (data) => {
    const { sbscId } = data.data;
    setCurrentSelected((prev) => (prev.includes(sbscId) ? prev.filter((id) => id !== sbscId) : [...prev, sbscId]));
  };

  const handleClickSelectAll = () => {
    const allSbcId = state.data.map((el) => el.sbscId);
    setCurrentSelected(allSbcId)
  }


  const handleClickSelectNone = () => {
    setCurrentSelected([]);
  }

  const location = useLocation(); // 현

  useEffect(() => () => {
    dispatch(resetSettlementManagementList());
    dispatch(resetPostSettlementStatusState());
  }, [dispatch, location.pathname]);


  const filteredOption = useMemo(
    () => getOptionSearchKeywords(isGSAdmin, getSelectOptionsByCode).filter((el) => el.value === "003" || el.value === "002"),
    [isGSAdmin, getSelectOptionsByCode],
  )

  const summaryData = useMemo(() => {
    const calculatedData = state.data.reduce((acc, curr) => {
      const exists = acc.some((item) => item.salesChnlNm === curr.salesChnlNm && item.corpAli === curr.corpAli);
      if (!exists) acc.push(curr);
      return acc;
    }, []);

    return calculatedData.map((data) => ({...data, selected: currentSelected.includes(data.sbscId)}));
  }, [state.data, currentSelected])


  const calculateComp = (needExClude) => {
    const selectedCompData = currentSelected.map((el) => {
      const findCompData = summaryData.find((data) => (data.sbscId === el && data.isSetlExclusion === (needExClude ? "N" : "Y")));

      if (findCompData) {
        return {
          corpId:findCompData.corpId,
          salesChnlCd:findCompData.salesChnlCd,
        }
      }
      return null;
    }).filter((el) => el !== null)

    return selectedCompData;
  }

  const handleRowClick = (rowInfo) => {
    setcompListBaseData(rowInfo.data);
    setShowCompPopup(true)
  }

  const compListData = useMemo(() => {
    if (compListBaseData === null || !state.data.length) return [];
    const sortedData = state.data.filter((el) => el.salesChnlNm === compListBaseData.salesChnlNm && el.corpId === compListBaseData.corpId);


    return sortedData;
  }, [compListBaseData, state.data])


  return (
    <>
      <SelectedCompanyListModal
        showModal={showCompPopup}
        onClose={() => setShowCompPopup(false)}
        listData={compListData}
      />
      <SettlementManagerModal
        showModal={showModal}
        type={modalContents.type || null}
        status={modalContents.status}
        count={modalContents.count}
        onClick={handleClickExcludeProcessInModal}
        onClose={handleCloseModal}
      />
      <AdminPageLayout title="정산 대상 관리" active subtitles={subtitles}>
        <SearchPaper border className="searchPaper">
          <StyledForm
            ref={formRef}
            enterSubmit
            onFormItemChange={onFormItemChange}
            onFormSubmit={onSubmit}
          >
            {isGSAdmin && (
              <StyledCheckBoxEContract type="MultipleRow">
                <StyledCheckGroupItem
                  type="RADIO"
                  name="salesChnlCd"
                  title="판매채널"
                  options={salesChnlCdRadioOptions}
                  defaultValue=""
                  placeholder="전체"
                />
                <StyledSubscriptionStatus type="MultipleRow">
                  <StyledSingleFormItem title="정산제외대상" />
                  <StyledSelectItem
                    type="Text"
                    name="isSbscSetlExclusion"
                    options={[{value:"ALL", label:"전체"}, {value:"Y", label:"Y"}, {value:"N", label:"N"}]}
                    onChange={settlementExcludeStateChange}
                    defaultValue={{value:"ALL", label:"전체"}}
                    placeholder="선택"
                    size="medium"
                  />
                </StyledSubscriptionStatus>

              </StyledCheckBoxEContract>
            )}

            <StyledMultipleRow type="MultipleRow">
              <StyledTypeTextOrTextArea
                name="srchKwrd"
                selectName="srchKwrdTypNm"
                title="검색어"
                placeholder="검색어를 입력해주세요."
                textAreaPlaceHolder={`복수의 법인번호를 ‘,’로 구분해서 입력한 뒤, ‘조회’ 버튼을 클릭해주세요.
ex.) 1234561234567,9876549876543
(법인번호는 최대 100개까지 조회할 수 있습니다.)`}
                width="160px"
                customClassnameDropdown="width-select-auto"
                options={filteredOption}
                onChange={onChange}
                onChangeSelect={changeSelectOption}
                validation={[
                  {
                    type: 'SUBMIT',
                    func: (_, formData) => {
                      if (!formData.srchKwrdTypNm) return false;
                      return true;
                    },
                    message: MENU_MESSAGES.SS_T_002,
                  },
                  {
                    type: 'SUBMIT',
                    func: (_, formData) => {
                      if (formData.srchKwrd?.length < 2) return false;
                      if (formData.srchKwrd === null) return false;
                      return true;
                    },
                    message: MENU_MESSAGES.SS_T_001,
                  },
                  {
                    type: 'SUBMIT',
                    func: (value, formData) => {
                      if (formData.srchKwrdTypNm === "003") {
                        const textSplitData = (formData.srchKwrd || "").split(",").filter((el) => !!el).map((el) => el.trim());
                        if (textSplitData.length > 100) return false
                      }
                      return true;
                    },
                    message: MENU_MESSAGES.SS_T_106,
                  },

                ]}
              />
            </StyledMultipleRow>
          </StyledForm>
          <FooterWrap>
            <Space gap="8px">
              <Button
                type="monochrome"
                size="medium"
                iconSrc={Images.iconRefresh}
                onClick={onClickResetBtn}
              >
                초기화
              </Button>
              <Button
                type="primary"
                size="medium"
                onClick={onClickSearchBtn}
                width="150px"
              >
                조회
              </Button>
            </Space>
          </FooterWrap>
        </SearchPaper>
        <TableWrap>
          <CustomTableTop>
            <StyledTitle
              level="3"
              title="조회 결과"
              subTitle={`총 ${state.pagination.total}건`}
            />

            <div style={{ display: 'flex', alignItems: 'center', gap:10 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap:10 }}>
                <Button
                  type="primary"
                  size="medium"
                  onClick={handleClickSelectAll}
                  width="120px"

                >
                  전체 선택
                </Button>
                <Button
                  type="primary"
                  size="medium"
                  onClick={handleClickSelectNone}
                  width="120px"

                >
                  전체 선택 해제
                </Button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap:10 }}>
                <Button
                  type="primary"
                  size="medium"
                  onClick={handleClickExcludeBtn}
                  width="150px"
                  disabled={currentSelected.length === 0}
                >
                  정산제외대상 설정
                </Button>
                <Button
                  type="primary"
                  size="medium"
                  onClick={handleClickIncludeBtn}
                  width="150px"
                  disabled={currentSelected.length === 0}
                >
                  정산제외대상 제외
                </Button>
              </div>
            </div>
          </CustomTableTop>
          <RGDTableSettlementManager
            onRowSelect={handleRowSelectClick}
            onRowClick={handleRowClick}
            ref={tableRef}
            columns={RDGColumnOptionsV2SummaryPage}
            data={summaryData}
            pagination={state.pagination}
            loading={state.loading}
            warningTitle="조회 결과가 없습니다."
            emptyText="조회 결과가 없습니다."
            hoverRows
            className="rdg-column-options-v2"
          />
        </TableWrap>
      </AdminPageLayout>
    </>
  );
}

export default SettlementCompanyList;
