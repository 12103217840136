import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import rumViewInfos from '../../../../constants/rumViewInfos';
import SettlementCompanyList from '../components/SettlementCompanyList';
import { resetStore, setActivePage } from '../redux/slice';

datadogRum.startView(rumViewInfos.settlementManager);

/**
*  구독 관리 컴포넌트
* @ param
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-07-13
* @ modifier
* @ update
*/
function SettlementManagerContainer() {
  const dispatch = useDispatch();

  const { activePageId, activePage } = useSelector((state) => ({
    activePageId: state.common.activePageId,
    sbscId: state.subscribeManagement.settlementManger.sbscId,
    activePage: state.subscribeManagement.settlementManger.activePage,
  }));


  useEffect(() => {
    dispatch(setActivePage(activePage));
  }, [activePageId]);



  useEffect(() => () => dispatch(resetStore()), []);



  return (
    <div>
      <SettlementCompanyList isActivePage />
    </div>
  );
}

export default SettlementManagerContainer;
