import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { Input} from 'antd';

import moment from 'moment';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { COLORS } from '../../../styles/Colors';

import { SvgAlert, SvgArrowDropdown } from '../../../Images';
import CategoryLoading from '../../Loading/components/CategoryLoading';

/**
  * 폼 내부의 select 컴포넌트
  *
  * @param {name} 폼 필드의 name 식별자
  * @param {className} className 상속
  * @param {width} 컴포넌트 널이
  * @param {title} 폼 필드 제목
  * @param {defaultValue} 폼 필드의 기본값 ( Object 형식 )
  * @param {onChange} onChange callback 함수.(상위에서 가져온 props)
  * onChangeSelect
  * @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
  * @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
  * @param {required} 필수여부
  * @param {validation} 유효성 체크 array
  * @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
  * @param {placeholder} text input 내 안내 문구
  * @param {options} select input 옵션 목록
  * @param {*} ref
  * @returns
  *
  * @ author 노민규
  * @ date 2022-06-28
  * @ modifier 노민규
  * @ update 2022-06-30
  */

function TypeText({
  name,
  selectName,
  className,
  width,
  title,
  onChange,
  onChangeSelect,
  getFormData,
  formItemChange,
  loading,
  required,
  validation,
  selectValidation,
  disabled,
  error,
  defaultValue = {
    value: null,
    select: null,
  },
  placeholder = '',
  selectPlaceHolder = '',
  options = [],
  onPressEnter,
  maxLength,
  errorTooltip,
  tooltipLeft,
  tooltipWidth,
  dropdownHeight,
  customClassnameDropdown,
}, ref) {
  const initialState = {
    value: defaultValue?.value ? defaultValue.value : null,
    select: defaultValue?.select !== undefined ? defaultValue.select : null,
  };

  const { TextArea } = Input;
  const [state, setState] = useState(initialState);
  const [dropdownKey, setDropdownKey] = useState(moment().unix());
  const [errorField, setErrorField] = useState(null);

  useEffect(() => {
    setErrorField(error);
  }, [error]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state.value, formData);
    }
  }, [state.value]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(selectName, state.select, formData);
    }
  }, [state.select]);

  const updateState = useCallback((value) => {
    setState({ ...state, ...value });
  }, [state]);

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value || !state.select) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }
    if (!validation && !selectValidation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state.value, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });

    selectValidation?.forEach((item) => {
      if (item.func && !item.func(state.select, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });

    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData, formItemChange]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && (!formData[name] || !formData[selectName])) {
        return false;
      }
      if (disabled) return false;
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state.value && !disabled) {
        return { [name]: state.value, [selectName]: state.select };
      } if (state.select && !disabled) {
        return { [name]: state.value, [selectName]: state.select };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const handleChange = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    let { value } = e.target;
    if (maxLength) {
      if (e.target.value.length > maxLength) {
        value = e.target.value.slice(0, maxLength);
      }
    }
    updateState({ value: state.isNumber ? value.replace(/[^0-9]/g, '') : value });
    if (changeValidation && changeValidation.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e.target.value };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e.target.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange(e.target.value);
  }, [validation, onChange, getFormData, state]);

  const handleChangeSelect = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));

    updateState({ select: e, isNumber: options?.find((item) => item.value === e)?.isNumber });
    if (changeValidation && changeValidation.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(state.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    // 해당 소스 필요없어보이므로 주석처리
    // if (onChange) onChange(e);

    if (onChangeSelect) {
      onChangeSelect(e);
    }
  }, [validation, onChange, getFormData, state]);

  const renderError = useCallback(() => {
    if (errorField) {
      return errorTooltip ? (
        <ErrorWrapTooltip tooltipLeft={tooltipLeft} tooltipWidth={tooltipWidth}>
          <SvgAlert />
          {errorField}
        </ErrorWrapTooltip>
      ) : (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const handleOnPressEnter = () => {
    if (onPressEnter) onPressEnter(true);
  };

  const getSelectPlaceHolder = () => {
    if (loading) return '불러오는 중...';
    return selectPlaceHolder;
  };

  useEffect(() => {
    if (state.isNumber) {
      updateState({ value: '' });
    }
  }, [state.select]);

  const renderMenu = useCallback((menu) => <div key={dropdownKey}>{menu}</div>, [dropdownKey]);

  const onVisibleChange = (visible) => {
    if (visible) {
      setDropdownKey(moment().unix());
    }
  };

  return (
    <Container className={cn(className)}>
      {title && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? (<span>*</span>) : null}
        </div>
      )}
      <div className="content">
        <ContentWrap>
          <FormWrap>
            <SelectDropDownV2
              onDropdownVisibleChange={onVisibleChange}
              dropdownRender={(menu) => renderMenu(menu)}
              width={width}
              onChange={handleChangeSelect}
              disabled={!!loading || disabled}
              options={options}
              value={state.select}
              placeholder={getSelectPlaceHolder()}
              loading={loading}
              suffixIcon={loading ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
              listHeight={dropdownHeight}
              popupClassName={customClassnameDropdown}
              size="medium"
              name={selectName}
            />
            <SingleInput placeholder={placeholder} disabled={disabled} value={state.value} onChange={handleChange} onPressEnter={handleOnPressEnter} />
          </FormWrap>
          {renderError()}
        </ContentWrap>
      </div>
    </Container>

  );
}

const Container = styled.div`
    display: flex;
    padding: 7px 0;
  
    &.off {
      display: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
    }
    .title {
      position: relative;
    }
    .title.required span {
      color: ${COLORS.RED[500]};
    }
    .rmsc .clear-selected-button{
        margin-right:15px !important;
    }
  `;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const FormWrap = styled.div`
  display: flex;
`;

const SingleInput = styled(Input)`
  flex: 1;
  height: 34px;
margin-left: 5px;
  box-sizing: border-box;

  &[disabled] {
    background: #f5f5f5;
  }
`;

const ErrorWrapTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  transform: translateY(-100%);
  top: -6px;
  line-height: 18px;
  position: absolute;
  background: white;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
  width: ${(props) => (props.tooltipWidth ? `${props.tooltipWidth}px` : 'fit-content')};
  padding: 7px 12px;
  margin: 0;
  font-size: 12px;
  color: var(--color-red-500);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  left: ${(props) => (props.tooltipLeft ? `${props.tooltipLeft}px` : '145px')};
  height: 34px;
  svg {
    margin: 2px;
  }
`;

const ErrorWrap = styled.div`
    margin-top:4px;
    width: 100%;
    height: auto;
    min-height: 18px;
    opacity: 1;
    color: #ff4d4f;
    font-size: 12px;
    line-height: 18px;
  
    span {
      padding-left: 1px;
      img {
        width: 14px;
      }
      svg {
        margin-right: 2px;
      }
    }
  `;

export default forwardRef(TypeText);
