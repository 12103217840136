import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import ArrowToggle from '@components/ArrowToggle';
import { alertMessage, confirmMessage } from '@components/Message';
import { USR_CORP_MASTER } from '@constants/define';

import { useMessage } from '@hooks/useMessage';
import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';
import { updateStore } from '@redux/commonReducer';

import { MenuItemBox } from './styled';

function MenuItem({
  id,
  name,
  icon,
  linkUrl,
  openClose,
  disabled,
  isAdminMenu,
  subMenu,
  menuLvl,
  activePageId,
  onClickMenu,
}) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.common);
  const { openPage } = usePageTab();
  const { messages, createMessage } = useMessage('COMMON');

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(openClose !== 'close');
  }, []);

  useEffect(() => {
    if (subMenu?.length) {
      const isOpened = subMenu.some((v) => v.id === activePageId);
      if (isOpened) setIsOpen(true);
    }
  }, [activePageId]);

  const openCorpChangePopup = () => {
    dispatch(updateStore({ showCorpChangePopup: true }));
  };

  const openPicePlanPage = () => {
    openPage('myPage', { step: 'price-plan', passSkip: true });
  };

  const handleClickMenuItem = () => {
    if (!linkUrl) {
      setIsOpen((v) => !v);
      return;
    }
    if (!disabled) {
      if (!isAdminMenu && !userInfo?.corpRegNo) {
        alertMessage(messages.CO_A_004, openCorpChangePopup);
      } else {
        onClickMenu(id);
      }
    } else if (userInfo?.roleId === USR_CORP_MASTER) {
      confirmMessage(
        messages.CO_A_001,
        openPicePlanPage,
        '요금제 보기',
        '취소',
      );
    } else {
      alertMessage(createMessage(messages.CO_A_002));
    }
  };

  return (
    <MenuItemBox
      className={cn({
        active: !!linkUrl && (id === activePageId),
        link: !subMenu?.length,
        disabled,
        main: id === 'main',
      })}
    >
      <div
        className="menu-item"
        style={menuLvl > 1 ? { paddingLeft: (menuLvl - 1) * 26 } : {}}
        onClick={handleClickMenuItem}
      >
        <span className="menu-item--icon">{icon}</span>
        <span className="menu-item--name">{name}</span>
        {subMenu && <ArrowToggle className="menu-item--arrow" open={isOpen} />}
      </div>

      {isOpen && subMenu?.map((v, idx) => (
        <MenuItem
          key={idx}
          id={v.id}
          name={v.name}
          icon={v.icon}
          linkUrl={v.linkUrl}
          openClose={v.openClose}
          disabled={v.disabled}
          subMenu={v.subMenu}
          menuLvl={v.menuLvl}
          isAdminMenu={v?.isAdminMenu}
          activePageId={activePageId}
          onClickMenu={onClickMenu}
        />
      ))}
    </MenuItemBox>
  );
}

export default MenuItem;
