import React from 'react';
import cn from 'classnames';

import { SvgIconCircleError } from '@Images';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { AdminPageLayoutBox } from './styled';

function AdminPageLayout({
  title,
  subtitles = [],
  headerRight = null,
  active = true,
  className,
  children,
  ...restProps
}) {
  const location = useLocation();

  return (
    <AdminPageLayoutBox
      className={cn('gip-apl', { 'gip-apl--hide': !active }, className)}
      {...restProps}
    >
      <div className="gip-apl__header">
        <h1 className="gip-apl__title">{title}</h1>
        {
          subtitles.length > 0 && (
            <div className="gip-apl__subtitle">
              <SvgIconCircleError className="gip-apl__subtitle__icon" width={20} height={20} />
              <div className="gip-apl__subtitle__content">
                { subtitles.map((subtitle, i) => (
                  <p key={`gip-apl__subtitle-${i}`}>
                    {subtitle}
                    {
                      location.pathname === "/settlement/manager" && i === 1 && (
                        <>
                          <span className="gip-apl_subtitle-accent">※ 정산제외대상 설정은 매월 말일 자정 전까지 한 경우에만 해당월 정산이 제외 됩니다.</span>
                          )
                        </>
                      )

                    }
                  </p>
                ))}
              </div>
            </div>
          )
        }
        { headerRight && (
          <div className="gip-apl__header--right">
            {headerRight}
          </div>
        )}
      </div>
      <div className="gip-apl__contents">
        {children}
      </div>
    </AdminPageLayoutBox>
  );
}

export default AdminPageLayout;
