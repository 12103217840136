import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  settlementManagementList: asyncApiState.initial([]), // 조회결과
  postSettlementStatusState: asyncApiState.initial({}),
  activePage: 'list',
  sbscId: null,
};

export const { actions, reducer } = createSlice({
  name: 'subscribeManagement/settlementManager',
  initialState,
  reducers: {
    resetStore: (store, { payload }) => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    setActivePage: (store, { payload }) => ({
      ...store,
      activePage: payload,
    }),
    setSbscId: (store, { payload }) => ({
      ...store,
      sbscId: payload,
    }),
    // 조회
    getSettlementManagementList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementManagementList = asyncApiState.request(result);
    },
    getSettlementManagementListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementManagementList = asyncApiState.success(result);
    },
    getSettlementManagementListFailure: (store, { payload }) => {
      store.settlementManagementList = asyncApiState.error(payload);
    },
    resetSettlementManagementList: (store) => {
      store.settlementManagementList = asyncApiState.initial([]);
    },
    // 상태 변경
    postSettlementStatus: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postSettlementStatusState = asyncApiState.request(result);
    },
    postSettlementStatusSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postSettlementStatusState = asyncApiState.success(result);
    },
    postSettlementStatusFailure: (store, { payload }) => {
      const result = { ...payload.data || {} };
      store.postSettlementStatusState = asyncApiState.error(result);
    },
    resetPostSettlementStatusState: (store) => {
      store.postSettlementStatusState = asyncApiState.initial({});
    },
  },
});

export const {
  resetStore,
  updateStore,
  setActivePage,
  setSbscId,
  getSettlementManagementList,
  postSettlementStatus,
  resetSettlementManagementList,
  resetPostSettlementStatusState,
} = actions;

export default reducer;
