import { serializeParams } from '../utils/utils';

export const SubscribeManagement = ({ http }) => ({
  // 구독관리 리스트
  getSubscribeManagementList: ({ params, config }) => http.get(`/subscriptions?${serializeParams(params)}`),

  getSettlementManagementList: ({ params, config }) => http.post('/subscriptions/settlement/exclusions/list', { ...params }, config),
  // 구독 조회
  getSubscribeManagementDetail: ({ params, config }) => http.get(`/subscriptions/${params.sbscId}`, { }, config),
  // 구독 이력(히스토리)
  getSubscribeManagementDetailHistroy: ({ params, config }) => http.get(`/subscriptions/${params.sbscId}/history?${serializeParams({ page: params.page, size: params.size })}`, { }, config),

  // 요금제 조회(select)
  getPPSelectList: ({ params, config }) => http.get('/pp/admin/plans/sbsc', { params }, config),
  // 요금제 선택시 (금액, 제공 메뉴)
  getPPSelected: ({ params, config }) => http.get(`/pp/admin/plans/${params.ppId}/sbsc`, { }, config),
  // 정산코드 저장
  putSetlCdSave: ({ params, config }) => http.put(`/subscriptions/${params.sbscId}/settlement/`, { ...params.sbscSettlRequestDto }, config),
  //  요금제 변경, 구독해지 요청
  putSubscriptions: ({ params, config }) => http.put(`/subscriptions/${params.sbscId}/`, { ...params.sbscRequestDto }, config),
  // 구독신청
  postSubscriptions: ({ params, config }) => http.post('/subscriptions/subscription/', { ...params.sbscRequestDto }, config),
  // 정산 제외 설정
  postSettlementStatus: ({params, config}) => http.post('/subscriptions/settlement/exclusions', {...params}, config),
  // 구독관리 > 구독 조회 리스트
  getSubscribeViewsList: ({ params, config }) => http.get(`/subscriptions/corp/salesChnl?${serializeParams(params)}`, { }, config),
  // 전자계약 상태
  getSubscribeContract: ({ params, config }) => http.get(`/subscriptions/${params.sbscId}/contract`, { }, config),
  // 구독해지 이후 재구독, 구독 기간 연장(미구독-신청전 생성
  postSubscriptionsNew: ({ params, config }) => http.post('/subscriptions/subscription/newContract', { ...params.sbscRequestDto }, config),
  // 구독기간연장 버튼 클릭시에만 사용
  postExtendPeriodSbsc: ({ params, config }) => http.post(`/subscriptions/${params.sbscId}/extensionPeriod`, { }, config),
  // 전자계약 상태 재전송
  putSubscribeContract: ({ params, config }) => http.put(`/subscriptions/${params.sbscId}/contract`, { }, config),
  // 정산 히스토리
  getSubscSetlHistory: ({ params, config }) => http.get(`/subscriptions/${params.sbscId}/setl/history?${serializeParams({ page: params.page, size: params.size })}`, { }, config),
  getSubsCheck: ({ params, config }) => http.get(`/subscriptions/${params.sbscId}/check`, { params: { sbscReqCd: params.sbscReqCd } }, config),
})

export const PriceChangeAPL = ({ http }) => ({
  getPriceChangeHistoryList: ({ params }) => http.get(`/subscriptions/pricePlan/history?${serializeParams(params)}`),
});

export const TerminationAPL = ({ http }) => ({
  getTerminationHistoryList: ({ params }) => http.get(`/subscriptions/term/history?${serializeParams(params)}`),
});

export const ViewSettlementDetail = ({ http }) => ({
  getInitialize: ({ config }) => http.get('/settlement/status/initialize', { }, config),
  getSettlementStatusGrid: ({ params, config }) => http.get(`/settlement/status/grid?${serializeParams(params)}`, config),
  getSettlementStatusResult: ({ params, config }) => http.get('/settlement/status/result', { params }, config),
  getSettlementStatusDifference: ({ params, config }) => http.get('/settlement/status/difference', { params }, config),
  getSettlementStatusChange: ({ params, config }) => http.get('/settlement/status/change', { params }, config),
  getSettlementStatusCancel: ({ params, config }) => http.get('/settlement/status/cancel', { params }, config),
  getSettlementStatusScheduled: ({ params, config }) => http.get('/settlement/status/scheduled', { params }, config),
});

export const CustomInfo = ({ http }) => ({
  // 맞춤정보 리스트
  getCuioReqList: ({ params, _config }) => http.get(`/cuio/req-list?${serializeParams(params)}`),
  // 정보 제공 요청
  postPartReq: ({ params, config }) => {
    const fd = new FormData();

    // 파일 데이터 저장
    if (params?.files?.length) {
      for (let i = 0; i < params.files.length; i += 1) {
        fd.append('files', params.files[i].file);
      }
    } else {
      fd.append('files', new File([''], '', {}));
    }
    fd.append('reqTitl', params.reqTitl);
    fd.append('reqDtlCns', params.reqDtlCns);

    return http.post('/cuio/adm/partner/req', fd, config);
  },
  // 맞춤정보 상세
  getCuioDetail: ({ params, config }) => http.get(`/cuio/adm/${params.sbscId}`, {}, config),
  // 정보 제공 요청 수정
  putPartReq: ({ params, config }) => {
    const fd = new FormData();

    // 파일 데이터 저장
    if (params?.files?.length) {
      for (let i = 0; i < params.files.length; i += 1) {
        fd.append('files', params.files[i].file);
      }
    } else {
      fd.append('files', new File([''], '', {}));
    }
    fd.append('reqTitl', params.reqTitl);
    fd.append('reqDtlCns', params.reqDtlCns);

    return http.put(`/cuio/adm/${params.sbscId}/partner/req`, fd, config);
  },
  // 정보 제공 요청 철회
  putPartReqCancel: ({ params, config }) => http.put(`/cuio/adm/${params.sbscId}/cancel`, {}, config),

  // 정보 제공 요청 철회
  putPartAnswer: ({ params, config }) => http.put(`/cuio/adm/${params.sbscId}/partner/answer`, { ...params.cuioReqAnswerDto }, config),
  // 구독의 정사/증분류 코드 변경
  putSettlement: ({ params, config }) => http.put(`/cuio/adm/${params.sbscId}/settlement`, { ...params.cuioSetlDto }, config),
  // 정보제고 완료
  putCuioInfoRes: ({ params, config }) => {
    const fd = new FormData();
    // 파일 데이터 저장
    if (params?.files?.length) {
      for (let i = 0; i < params.files.length; i += 1) {
        fd.append('files', params.files[i].file);
      }
    }
    fd.append('infoOferRpyCns', params.infoOferRpyCns);

    return http.put(`/cuio/adm/${params.sbscId}/info`, fd, config);
  },
  // 첨부파일 삭제
  deleteCuioFile: ({ params, config }) => http.delete(`/cuio/adm/file/${params.acesUrl}`, { }, config),
  // 정보제공요청 대상유무 (정산상태 대기일 경우)
  putSetlTarget: ({ params, config }) => http.put(`/cuio/adm/${params.sbscId}/settlement/target`, { }, config),
  // 이력 조회
  getProcHistory: ({ params, config }) => http.get(`/cuio/adm/${params.sbscId}/proc-history`, { params }, config),
  getCuioCorpInfoInit: ({ _params, config }) => http.get('/cuio/adm/init', {}, config),
});
