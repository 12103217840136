import styled from 'styled-components';

export const AdminPageLayoutBox = styled.div`
  &.gip-apl { 
    min-width: var(--contents-minWidth);
    max-width: var(--contents-maxWidth);
    padding: 60px 24px 60px;

    &--hide {
      display: none;
    }
    
    .gip-apl__header {
      position: relative;
      margin-bottom: 16px;
      &--right {
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .gip-apl__title {
        color: var(--color-gray-900);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .gip-apl__subtitle {
        display: flex;
        align-items: start;
        gap: 4px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 12px;
        &__icon {
          flex-basis: 20px;
        }
        &__content {
          flex-grow: 1;
          padding-top: 1px;
          p {
            color: var(--color-steelGray-500);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
        .gip-apl_subtitle-accent{
          color: #FD0013
        }
    }
  }
`;
