import { customerServiceFrontMenuId } from './define';

const apiVersion = '2.0.5.0';
const services = {
  dashboard: 'gip-pbu-fe-dashboard',
  admin: 'gip-pbu-fe-admin',
};
export default {
  root: {
    name: '/',
    service: services.admin,
    version: apiVersion,
  },
  myPage: {
    name: '마이페이지',
    service: services.admin,
    version: apiVersion,
  },
  join: {
    name: '회원가입',
    service: services.admin,
    version: apiVersion,
  },
  login: {
    name: '로그인',
    service: services.admin,
    version: apiVersion,
  },
  main: {
    name: '메인',
    service: services.admin,
    version: apiVersion,
  },
  user: {
    name: '사용자 관리',
    service: services.admin,
    version: apiVersion,
  },
  userDivision: {
    name: '사용자 구분 관리',
    service: services.admin,
    version: apiVersion,
  },
  partner: {
    name: '파트너 관리',
    service: services.admin,
    version: apiVersion,
  },
  supplier: {
    name: '거래처 관리',
    service: services.admin,
    version: apiVersion,
  },
  admin: {
    name: '관리자 관리',
    service: services.admin,
    version: apiVersion,
  },
  pricePlan: {
    name: '요금제 조회',
    service: services.admin,
    version: apiVersion,
  },
  subscribeManagement: {
    name: '구독 조회',
    service: services.admin,
    version: apiVersion,
  },
  priceChangeAPL: {
    name: '요금제 변경 신청 이력',
    service: services.admin,
    version: apiVersion,
  },
  terminationAPL: {
    name: '해지 신청 이력',
    service: services.admin,
    version: apiVersion,
  },
  subscribeViews: {
    name: '구독 조회',
    service: services.admin,
    version: apiVersion,
  },
  dataRequest: {
    name: '데이터 요청',
    service: services.admin,
    version: apiVersion,
  },
  customInfo: {
    name: '맞춤정보 제공',
    service: services.admin,
    version: apiVersion,
  },
  userStatus: {
    name: '파트너 상태 확인',
    service: services.admin,
    version: apiVersion,
  },
  popupNotice: {
    name: '팝업공지 관리',
    service: services.admin,
    version: apiVersion,
  },
  faqManagement: {
    name: 'FAQ 관리',
    services: services.admin,
    version: apiVersion,
  },
  noticeManagement: {
    name: '공지사항 관리',
  },
  customizedInformation: {
    name: 'customizedInformation',
  },
  qnaManagement: {
    name: '1:1 문의 관리',
  },
  mailManagement: {
    name: '메일 발송 관리',
    services: services.admin,
    version: apiVersion,
  },
  viewSettlementDetail: {
    name: '정산 내역 조회',
    services: services.admin,
    version: apiVersion,
  },
  settlementManager: {
    name: '정산 대상 관리',
    services: services.admin,
    version: apiVersion,
  },
  // 대시보드
  salesProductInfo: {
    name: '상품 취급 정보',
    service: services.dashboard,
    version: apiVersion,
  },
  salesByTime: {
    name: '시간대별 매출',
    service: services.dashboard,
    version: apiVersion,
  },
  salesByRegion: {
    name: '지역별 매출',
    service: services.dashboard,
    version: apiVersion,
  },
  salesByDay: {
    name: '요일별 매출',
    service: services.dashboard,
    version: apiVersion,
  },
  salesBaseInfo: {
    name: '매출 기본 정보',
    service: services.dashboard,
    version: apiVersion,
  },
  newItemAnalysis: {
    name: '신상품 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  newItemEvent: {
    name: '행사 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  unpaidStatus: {
    name: '미납 현황',
    service: services.dashboard,
    version: apiVersion,
  },
  itemStockStatus: {
    name: '재고 현황',
    service: services.dashboard,
    version: apiVersion,
  },
  itemsProductOrder: {
    name: '발주 추이',
    service: services.dashboard,
    version: apiVersion,
  },
  customerStoreType: {
    name: '점표 유형 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  customerPR: {
    name: '지역별 구매 고객 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  customerPurchaseQty: {
    name: '구매 수량 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  customerPF: {
    name: '구매 빈도 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  customerPurchaseCount: {
    name: '고객 구매 건수 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  customerPurchaseAgeGroup: {
    name: '연령대별 구매 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  customerGS: {
    name: '성별 구매 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  categorySales: {
    name: '카테고리별 매출 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  categoryStore: {
    name: '카테고리별 취급 점포 분석',
    service: services.dashboard,
    version: apiVersion,
  },
  faq: {
    name: '1:1 문의 관리',
    services: services.dashboard,
    version: apiVersion,
  },
  qna: {
    name: '1:1 문의',
    services: services.dashboard,
    version: apiVersion,
  },
  inventoryStatus: {
    name: '점포.센터 재고 현황',
    services: services.dashboard,
    version: apiVersion,
  },
  menuInfo: {
    MN000000000: '메인',
    MN000000001: '회원 관리',
    MN000000002: '사용자 관리',
    MN000000003: '사용자 구분 관리',
    MN000000004: '파트너 관리',
    MN000000005: '거래처 관리',
    MN000000006: '관리자 관리',
    MN000000007: '요금제 관리',
    MN000000008: '요금제 조회',
    MN000000009: '구독 관리',
    MN000000010: '구독 관리',
    MN000000011: '요금제 변경 신청 이력',
    MN000000012: '해지 신청 이력',
    MN000000013: '구독 조회',
    MN000000014: 'C/S 관리',
    MN000000015: '데이터 요청',
    MN000000018: '파트너 상태 확인',
    MN000000019: '팝업공지 관리',
    MN000000020: '공지 사항 관리',
    MN000000021: '메일 발송 관리',
    MN000000022: 'FAQ 관리',
    MN000000023: '1:1 문의 관리',
    [customerServiceFrontMenuId]: '고객 센터',
    MN000000025: '공지 사항',
    MN000000026: '1:1 문의',
    MN000000027: 'FAQ',
    IM000000001: '재고 현황',
    IM000000002: '발주 추이',
    IM000000003: '미납 현황',
    IM000000004: '매출 기본 정보',
    IM000000005: '상품 취급 정보',
    IM000000006: '시간대별 매출',
    IM000000007: '요일별 매출',
    IM000000008: '지역별 매출',
    IM000000009: '신상품 분석',
    IM000000010: '행사 분석',
    IM000000011: '성별 구매 분석',
    IM000000012: '연령대별 구매 분석',
    IM000000013: '구매 빈도 분석',
    IM000000014: '고객 구매 건수 분석',
    IM000000015: '구매 수량 분석',
    IM000000016: '점포 유형 분석',
    IM000000017: '지역별 고객 구매 분석',
    IM000000018: '카테고리별 매출 분석',
    IM000000019: '카테고리별 취급 점포 분석',
    IM000000024: '점포.센터 재고 현황',
  },
};
