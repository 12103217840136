import Button from "@components/ButtonNew";
import RGDTableSettlementManager from "@components/ReactdatagridSettlementManager";
import { Modal } from "antd";
import { useRef } from "react";

import styled from "styled-components";

import { RDGColumnOptionsV2 } from "../../constants";

function SelectedCompanyListModal({
  showModal,
  onClose,
  listData}) {
  const tableRef = useRef(null);

  if (!showModal) return null;


  const onRowClick = () => {}

  return (
    <CustomModal
      title="compListModal"
      centered
      open={showModal}
      onOk={onClose}
      onCancel={onClose}
      width="70%"
      footer={(
        <Button
          key="done"
          type="primary"
          size="medium"
          onClick={onClose}
          width="150px"
        >
          닫기
        </Button>
      )}
    >
      <RGDTableSettlementManager
        ref={tableRef}
        onRowClick={onRowClick}
        columns={RDGColumnOptionsV2}
        data={[...listData]}
        warningTitle="조회 결과가 없습니다."
        emptyText="조회 결과가 없습니다."
        hoverRows
        className="comp-list-modal"
      />
    </CustomModal>

  )
}

const CustomModal = styled(Modal)`
.ant-modal-header{
height:30px;
.ant-modal-title{
display:none;}
}

.ant-modal-content{
padding:20px;
height:700px;
}

.ant-modal-body{
height:85%;
overflow-y:scroll;
overflow-x:hidden;
}
`





export default SelectedCompanyListModal;
