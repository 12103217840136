import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import commonReducer from './commonReducer';
import searchReducer from '../components/Search/redux/slice';
import loginReducer from '../views/account/login/redux/slice';
import joinReducer from '../views/account/join/redux/slice';
import userReducer from '../views/member/user/redux/slice';
import userDivisionReducer from '../views/member/userDivision/redux/slice';
import userStatusReducer from '../views/member/userStatus/redux/slice';
import adminReducer from '../views/member/admin/redux/slice';
import partnerReducer from '../views/member/partner/redux/slice';
import pricePlanReducer from '../views/pricePlan/redux/slice';
import supplierReducer from '../views/supplier/supplier/redux/slice';
import subscribeManagementReducer from '../views/subscribeManagement/subscribeManagement/redux/slice';
import priceChangeAPLReducer from '../views/subscribeManagement/priceChangeAPL/redux/slice';
import terminationAPLReducer from '../views/subscribeManagement/terminationAPL/redux/slice';
import viewSettlementDetailReducer from '../views/subscribeManagement/viewSettlementDetail/redux/slice';
import settlementManagerReducer from '../views/subscribeManagement/settlementManager/redux/slice';
import popupNoticeReducer from '../views/customerService/popupNotice/redux/slice';
import myPageReducer from '../views/main/myPage/redux/slice';
import salesBaseInfoReducer from '../views/salesAnalysis/salesBaseInfo/redux/slice';
import salesProductInfoReducer from '../views/salesAnalysis/salesProductInfo/redux/slice';
import salesByTimeReducer from '../views/salesAnalysis/salesByTime/redux/slice';
import salesByDayReducer from '../views/salesAnalysis/salesByDay/redux/slice';
import salesByRegionReducer from '../views/salesAnalysis/salesByRegion/redux/slice';
import centerSalesReducer from '../views/salesAnalysis/centerSales/redux/slice';
import customerPFReducer from '../views/customer/customerPurchaseFrequency/redux/slice';
import customerPurchaseCountReducer from '../views/customer/customerPurchaseCount/redux/slice';
import customerPRReducer from '../views/customer/customerPurchaseRegion/redux/slice';
import customerPurchaseQtyReducer from '../views/customer/customerPurchaseQty/redux/slice';
import customerStoreTypeReducer from '../views/customer/customerStoreType/redux/slice';
import customerPurchaseAgeGroupReducer from '../views/customer/customerPurchaseAgeGroup/redux/slice';
import customerGenSalesReducer from '../views/customer/customerGenSales/redux/slice';
import itemStockStatusReducer from '../views/item/itemStockStatus/redux/slice';
import categorySalesReducer from '../views/category/categorySales/redux/slice';
import itemsProductOrderReducer from '../views/item/itemsProductOrder/redux/slice';
import newItemAnalysisReducer from '../views/newItem/newItemAnalysis/redux/slice';
import categoryStoreReducer from '../views/category/categoryStore/redux/slice';
import categoryTrendReportReducer from '../views/category/categoryTrendReport/redux/slice';
import newItemEventReducer from '../views/newItem/newItemEvent/redux/slice';
import unpaidStatusReducer from '../views/item/unpaidStatus/redux/slice';
import customizedInformationReducer from '../views/customerService/requestCustomizedInformation/redux/slice';
import faqManagementReducer from '../views/customerService/faqManagement/redux/slice';
import noticeManagementReducer from '../views/customerService/noticeManagement/redux/slice';
import faqReducer from '../views/customerService/faq/redux/slice';
import noticeReducer from '../views/customerService/notice/redux/slice';
import qnaManagementReducer from '../views/customerService/qnaManagement/redux/slice';
import qnaReducer from '../views/customerService/qna/redux/slice';
import mailManagementReducer from '../views/customerService/mailManagement/redux/slice';
import inventoryStatusReducer from '../views/item/inventoryStatus/redux/slice';
import additionalServicesReducer from '../views/subscribeManagement/additionalServices/redux/slice';

export const sagaMiddleware = createSagaMiddleware();

// 각 페이지에 잇는 reducer를 통합하여 store를 만든다.
export const store = configureStore({
  reducer: {
    common: commonReducer,
    search: searchReducer,
    account: combineReducers({
      login: loginReducer,
      join: joinReducer,
    }),
    member: combineReducers({
      user: userReducer,
      userDivision: userDivisionReducer,
      admin: adminReducer,
      partner: partnerReducer,
      userStatus: userStatusReducer,
    }),
    supplier: combineReducers({
      supplier: supplierReducer,
    }),
    pricePlan: pricePlanReducer,
    subscribeManagement: combineReducers({
      subscribeManagement: subscribeManagementReducer,
      priceChangeAPL: priceChangeAPLReducer,
      terminationAPL: terminationAPLReducer,
      viewSettlementDetail: viewSettlementDetailReducer,
      settlementManger: settlementManagerReducer,
    }),
    customerService: combineReducers({
      popupNotice: popupNoticeReducer,
      faqManagement: faqManagementReducer,
      qnaManagement: qnaManagementReducer,
      faq: faqReducer,
      noticeManagement: noticeManagementReducer,
      notice: noticeReducer,
      qna: qnaReducer,
      mailManagement: mailManagementReducer,
      customizedInformation: customizedInformationReducer,
    }),
    main: combineReducers({
      myPage: myPageReducer,
    }),
    salesAnalysis: combineReducers({
      salesBaseInfo: salesBaseInfoReducer,
      salesProductInfo: salesProductInfoReducer,
      salesByTime: salesByTimeReducer,
      salesByDay: salesByDayReducer,
      salesByRegion: salesByRegionReducer,
      centerSales: centerSalesReducer,
    }),
    customer: combineReducers({
      customerPF: customerPFReducer,
      customerPurchaseCount: customerPurchaseCountReducer,
      customerPR: customerPRReducer,
      customerPurchaseQty: customerPurchaseQtyReducer,
      customerStoreType: customerStoreTypeReducer,
      customerPurchaseAgeGroup: customerPurchaseAgeGroupReducer,
      customerGS: customerGenSalesReducer,
    }),
    item: combineReducers({
      itemStockStatus: itemStockStatusReducer,
      itemsProductOrder: itemsProductOrderReducer,
      unpaidStatus: unpaidStatusReducer,
      inventoryStatus: inventoryStatusReducer,
    }),
    category: combineReducers({
      categorySales: categorySalesReducer,
      categoryStore: categoryStoreReducer,
      categoryTrendReport: categoryTrendReportReducer,
    }),
    newItem: combineReducers({
      newItemAnalysis: newItemAnalysisReducer,
      newItemEvent: newItemEventReducer,
    }),
    additionalServices: additionalServicesReducer,
  },
  middleware: [sagaMiddleware],
});
