import React, { useState } from 'react';
import { Modal } from 'antd';
import Button from '@components/ButtonNew';
import styled from 'styled-components';

function SettlementManagerModal({ showModal = true, type, status, count = 1, onClick, onClose }) {
  if (!showModal) return null;


  return (
    <StyledModal
      visible={showModal}
      onCancel={onClose}
      footer={null}
      centered
      width={400}
    >

      {type === "EXCLUDE"
      && (
        <ModalContent>
          {status === "READY"
&& (
  <>
    <p>
      정산 제외 대상으로
      <Highlight>설정</Highlight>
      하시겠어요?
    </p>
    <p>
      <Count>
        총
        {count.toLocaleString()}
        건
      </Count>
      선택
    </p>
    <ButtonWrap>
      <Button
        width="150px"
        type="monochrome"
        size="large"
        onClick={() => onClose(false)}
      >
        취소
      </Button>
      <Button
        width="150px"
        type="primary"
        size="large"
        onClick={onClick}
      >
        확인
      </Button>

    </ButtonWrap>
  </>
)}
          {status === "DONE"
  && (
    <>
      <p>
        <Count>
          총
          {count.toLocaleString()}
          건
        </Count>
        이 정산 제외 대상으로
        <Highlight>설정</Highlight>
        되었습니다.
      </p>
      <ButtonWrap>
        <Button
          width="150px"
          type="primary"
          size="large"
          onClick={() => onClose(true)}
        >
          확인
        </Button>
      </ButtonWrap>

    </>
  )}
        </ModalContent>
      )}

      {type === "INCLUDE"
      && (
        <ModalContent>
          {status === "READY"
&& (
  <>
    <p>
      정산 제외 대상으로
      <Highlight>해제</Highlight>
      하시겠어요?
    </p>
    <p>
      <Count>
        총
        {count.toLocaleString()}
        건
      </Count>
      선택
    </p>
    <ButtonWrap>
      <Button
        width="150px"
        type="monochrome"
        size="large"
        onClick={() => onClose(false)}
      >
        취소
      </Button>
      <Button
        width="150px"
        type="primary"
        size="large"
        onClick={onClick}
      >
        확인
      </Button>

    </ButtonWrap>
  </>
)}
          {status === "DONE"
  && (
    <>
      <p>
        <Count>
          총
          {count.toLocaleString()}
          건
        </Count>
        이 정산 제외 대상에서
        <Highlight>해제</Highlight>
        되었습니다.
      </p>
      <ButtonWrap>
        <Button
          width="150px"
          type="primary"
          size="large"
          onClick={() => onClose(true)}
        >
          확인
        </Button>
      </ButtonWrap>

    </>
  )}
        </ModalContent>
      )}

    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 20px 0px;
    border-radius: 8px; 
  }

  .ant-modal-close{
  display:none;
  }
`;

const ModalContent = styled.div`
  text-align: left;

`;

const Highlight = styled.span`
padding:0px 5px;
  color: #F00000;
  font-weight: bold;
`;

const Count = styled.span`
  color: #0A8CFA;
  font-weight: bold;
  padding-right:2px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap:15px;
`;


export default SettlementManagerModal;
