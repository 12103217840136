import moment from 'moment';

import { isNil, truncate } from 'lodash-es';

import checkboxNo from '@Images/checkbox_circle_no_checked.svg';

import checkboxYes from '@Images/checkbox_circle_checked.svg';

import { transformDateDashString, transformDateDotStringMinute } from '../../../../utils/utils';
import TooltipHeader from '../components/TooltipHeader';



// 특정 에러 코드
// export const subscErrorCode = ['0321', '0303', '0318', '0320', '9900', '9600'];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

const mdCtrtStNmCellRender = (text) => {
  if (text === '미계약' || text === '계약실패' || text === null) {
    return (
      <span style={{ color: 'red' }}>{text || '미계약'}</span>
    );
  }
  return (
    <span>{text}</span>
  );
};

export const confirmMessageDetail = (
  <>
    정산코드, 중분류코드 변경 후 &lsquo;저장&lsquo; 버튼을 클릭해야 변경 내용이 저장됩니다.
    <br />
    정산코드, 중분류코드 변경 내용을 저장하지 않고 계속 진행하시겠어요?
  </>
);


export const RDGColumnOptionsV2SummaryPage = [
  {
    header: "선택",
    name: 'selected',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    className:"checkbox-container",
    sortable: false,
    minWidth: 80,
    maxWidth:80,
    defaultFlex: 1,
    render: ({ value }) => (
      <div style={{width:'100%', height:'50px', display:'flex', justifyContent:'center', alignItems:'center'}} className="check-svg-wrap">
        <img
          src={value ? checkboxYes : checkboxNo}
          alt="checkbox"
        />
      </div>
    ),
  },
  {
    header: (
      <>
        법인명
        <br />
        (법인번호)
      </>
    ),
    name: 'corpAli',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 210,
    defaultFlex: 1,
    render: ({ value, data }) => (value ? (
      <>
        <p>{truncate(value, { length: 15, separator: '...' })}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data?.corpRegNo})`}
        </p>
      </>
    ) : '-'),
  },
  {
    header: '판매채널',
    name: 'salesChnlNm',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultFlex: 1,
    render: ({ value }) => value || '-',
  },
  {

    header: (
      <span style={{color:"red"}}>정산제외대상</span>
    ),
    name: 'isSetlExclusion',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultFlex: 1,
    render: ({ value }) => (value ? (
      <>
        <p style={{color:value === "Y" ? "red" : 'black'}}>{value}</p>
      </>
    ) : '-'),
  },
];


export const RDGColumnOptionsV2 = [
  {
    header: '구독번호',
    name: 'sbscId',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultFlex: 1,
    render: ({ value }) => value || '-',
  },

  {
    header: (
      <>
        전자계약
        <br />
        상태
      </>
    ),
    name: 'mdCtrtStNm',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 80,
    defaultFlex: 1,
    render: ({ value }) => mdCtrtStNmCellRender(value) || '-',
  },
  {
    header: '구독상태',
    name: 'sbscStNm',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultFlex: 1,
    render: ({ value, data }) => (value ? (
      <>
        <p>{value}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data?.sbscStDvNm})`}
        </p>
      </>
    ) : '-'),
  },
  {
    header: '구독 신청일',
    name: 'sbscAplDtm',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 160,
    defaultFlex: 1,
    render: ({ value }) => (value ? `${moment(value, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}` : '-'),
  },
  {
    header: '구독 기간',
    name: 'sbscStaDtm',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 195,
    defaultFlex: 1,
    render: ({ value, data }) => (value ? (
      `
        ${transformDateDashString(value)} ~ ${transformDateDashString(data?.sbscEndDtm)}
      `
    ) : '-'),
  },
  {
    header: '기존 요금제명',
    name: 'ppNm',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 211,
    defaultFlex: 1,
    render: ({ value }) => {
      const result = value ? (value.length > 15 ? `${value.substr(0, 15)}...` : value) : '-';
      return result;
    },
  },
  {
    header: (
      <>
        (A)
        <br />
        기본 구독 월 이용료(원)
      </>
    ),
    name: 'ppBasicUsFeeAmt',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 140,
    defaultFlex: 1,
    render: ({ value }) => (!isNil(value) && !Number.isNaN(value) ? <div style={{ textAlign: 'end' }}>{value.toLocaleString()}</div> : '-'),
  },
  {
    header: (
      <>
        (B)
        <br />
        부가서비스 월 이용료(원)
        <TooltipHeader />
      </>
    ),
    name: 'ppAddUsFeeTotalAmt',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 160,
    render: ({ value }) => (!isNil(value) && !Number.isNaN(value) ? <div style={{ textAlign: 'end' }}>{value.toLocaleString()}</div> : '-'),
  },
  {
    header: (
      <>
        (A + B)
        <br />
        전체 월 이용료(원)
      </>
    ),
    name: 'totalAmt',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 140,
    defaultFlex: 1,
    render: ({ value }) => (!isNil(value) && !Number.isNaN(value) ? <div style={{ textAlign: 'end' }}>{value.toLocaleString()}</div> : '-'),
  },
  {
    header: '구독 신청자',
    name: 'sbscAplUsrNm',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 140,
    minWidth: 140,
    defaultFlex: 1,
    render: ({ value, data }) => (value ? (
      <>
        <p>{value}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data?.sbscAplLoginNm})`}
        </p>
      </>
    ) : '-'),
  },
];

export const columnOptions = [
  {
    title: '구독 번호',
    align: 'center',
    dataIndex: 'sbscId',
    fixed: 'left',
  },
  {
    title: (
      <>
        법인
        <br />
        (법인번호)
      </>
    ),
    align: 'center',
    dataIndex: 'corpAli',
    fixed: 'left',
    render: (text, record) => (
      <>
        {text && (
          <>
            {text.length > 9 ? (
              <>
                {text.substr(0, 9)}
                ...
              </>
            ) : (<>{text}</>)}
            <br />
            {/* {record.corpRegNo.substr(0, 6)}
        -
        {record.corpRegNo.substr(6, 7)} */}
            {record.corpRegNo}
          </>
        )}
      </>
    ),
  },
  {
    title: '판매채널',
    align: 'center',
    dataIndex: 'salesChnlNm',
    fixed: 'left',
    // width: 100,
  },
  {
    title: '전자계약 상태',
    align: 'center',
    dataIndex: 'mdCtrtStNm',
    render: (text, record) => mdCtrtStNmCellRender(text),
  },
  {
    title: '구독상태',
    align: 'center',
    dataIndex: 'sbscStNm',
    // render: (text, record) => `${text}(${record.sbscStDvNm})`,
    render: (text, record) => (
      <>
        {text}
        <br />
        (
        {record.sbscStDvNm}
        )
      </>
    ),

  },
  {
    title: '구독 기간',
    align: 'center',
    dataIndex: 'sbscStaDtm',
    // render: (text, record) => text && (`${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}~${moment(record.sbscEndDtm, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}`),
    render: (text, record) => text && (
      <>
        {transformDateDotStringMinute(text)}
        ~
        <br />
        {transformDateDotStringMinute(record.sbscEndDtm)}
      </>
    ),

  },
  {
    title: '요금제 구분',
    align: 'center',
    dataIndex: 'ppDvNm',
  },
  {
    title: '요금제명',
    align: 'center',
    dataIndex: 'ppNm',
    // render: (data) => {
    //   const result = data && data.length > 15 ? `${data.substr(0, 15)}...` : data;
    //   return result;
    // },
    render: (data) => {
      if (data) {
        return (
          <>
            {data.substr(0, 15)}
            <br />
            {data.substr(15)}
          </>
        );
      }
      return data;
    },
  },
  {
    title: '월 이용료(원)',
    align: 'center',
    dataIndex: 'usfeeAmt',
    render: (text, record) => (text ? text.toLocaleString() : ''),
  },
  {
    title: '총 구독 계약 금액(원)',
    align: 'center',
    dataIndex: 'totalAmt',
    render: (text, record) => (text ? text.toLocaleString() : ''),
  },
  {
    title: '구독 신청일',
    align: 'center',
    dataIndex: 'sbscAplDtm',
    render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm:ss')}`,
  },
  {
    title: '구독 신청자',
    align: 'center',
    dataIndex: 'sbscAplUsrNm',
    render: (text, record) => text && `${text}(${record.sbscAplLoginNm})`,
  },
  {
    title: '해지 신청일',
    align: 'center',
    dataIndex: 'termSbscAplDtm',
    render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm:ss')}`,
  },
  {
    title: '해지 신청자',
    align: 'center',
    dataIndex: 'termSbscAplUsrNm',
    render: (text, record) => text && `${text}(${record.termSbscAplLoginNm})`,
  },
  {
    title: '해지 승인자',
    align: 'center',
    dataIndex: 'termSbscProcUsrNm',
    render: (text, record) => text && `${text}(${record.termSbscProcLoginNm})`,

  },
  {
    title: '해지 승인일',
    align: 'center',
    dataIndex: 'termSbscProcDtm',
    render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm:ss')}`,
  },
  {
    title: '요금제 변경 신청일',
    align: 'center',
    dataIndex: 'ppSbscAplDtm',
    render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm:ss')}`,
  },
  {
    title: '요금제 변경 신청자',
    align: 'center',
    dataIndex: 'ppSbscAplUsrNm',
    render: (text, record) => text && `${text}(${record.ppSbscAplLoginNm})`,
  },
  {
    title: '요금제 변경 승인자',
    align: 'center',
    dataIndex: 'ppSbscProcUsrNm',
    render: (text, record) => text && `${text}(${record.ppSbscProcLoginNm})`,
  },
  {
    title: '요금제 변경 승인일',
    align: 'center',
    dataIndex: 'ppSbscProcDtm',
    render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm:ss')}`,
  },
];

export const SBSC_STATUS_CODE = {
  SBSC_REQ: '001',
  PP_CHG_APL: '002',
  PP_CHG_PROC: '003',
  PP_CHG_CANCEL: '004',
  PP_CHG_APL_CANCEL: '005',
  SBSC_TERM_APL: '006',
  SBSC_TERM_PROC: '007',
  SBSC_TERM_CANCEL: '008',
  SBSC_TERM_APL_CANCEL: '009',
  SBSC_EXTENSION: '010',
  SBSC_REQ_FAIL: '011',
  SBSC_FINISH_REQ: '012',
};
